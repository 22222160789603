module.exports = new Promise((resolve, reject) => {
		const isLocal = window.location.protocol === 'http:';
		if (!isLocal) {
			return reject();
		}

		const script = document.createElement('script');
		script.src = 'http://localhost:3003/remoteEntry.js';
		script.onload = () => {
			resolve(window.shared_components);
		};
		script.onerror = (err) => {
			console.error('Failed to load local shared_components... Retrying with stage deployment...', err);
			reject();
		};

		document.head.appendChild(script);
	}).catch(() => {
		return new Promise((resolve, reject) => {
			const host = window.location.host;
			const isProd = host.indexOf('p2.') >= 0;
			const url = isProd ? 'https://sharedcomponents-ent-ui-prod.aspendental.com' : 'https://sharedcomponents-ent-ui-stage.aspendental.com';

			const script = document.createElement('script');
			script.src = url+'/remoteEntry.js';
			script.onload = () => {
				resolve(window.shared_components);
			};
			script.onerror = (err) => {
				console.error('Failed to load deployed remote module!', err);
				reject();
			};

			document.head.appendChild(script);
		});
	});;